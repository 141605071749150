.btn-link {
  --bs-btn-padding-x: 0;

  box-shadow: none!important;

  &--secondary {
    --bs-btn-color: #{$secondary};
    --bs-btn-hover-color: #{darken($secondary, 10%)};
  }
}

.btn-text {
  font: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  border: none;
  color: inherit;
}

.btn {

  &-secondary {
    --bs-btn-color: #{$white};
  }

  &-icon {
    --bs-btn-color: inherit;
    --bs-btn-hover-border-color: #{$white};
    --bs-btn-border-width: 1px;
  }

  &--float {
    --bs-border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    width: 3rem;
    height: 3rem;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1), -3px -3px 6px rgba(0, 0, 0, 0.1);
    transition: border-radius .2s ease-out;

    .btn__description {
      display: none;
    }

    &:hover, &:focus {
      --bs-border-radius: #{$border-radius};
      width: auto;

      .btn__description {
        display: block;
      }
    }
  }

  &-xs {
    --bs-btn-padding-y: .1rem;
    --bs-btn-padding-x: .5rem;
    --bs-btn-font-size: .65rem;
    --bs-btn-border-radius: .25rem;
  }

}
