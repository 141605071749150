.world-tour {
  min-height: calc(100vh - 88px);
}

.in-fullscreen {
  .world-tour {
    z-index: 1000000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0!important;
    background-color: #fff;


    .magazine__headline {
      margin-top: 1rem;
    }

    .magazine__content, .timeline {
      margin-right: 3rem;
    }

    .magazine__content {
      max-height: calc(100vh - 189px)!important;
    }

  }

  .edit-button {
    display: none!important;
  }
}

.globe {

  width: 100%;

  &__container {
    padding: 10% 5%;
    z-index: 5;
    width: 100%;
    position: relative;

    @include media-breakpoint-down(sm) {
      padding: 11% 18%;
    }

  }

  &__inset {
    position: relative;
    padding-bottom: 100%;
  }

  &__canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 20px rgba(255, 255, 255, .6);
    border-radius: 50%;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;

    &:before {
      content: '';
      position: absolute;
      left: -.5 * $grid-gutter-width;
      top: 0;
      right: -.5 * $grid-gutter-width;
      // width: calc(100% + 45px);
      height: 100%;
      background-image: var(--background);
      background-color: #000;
      background-size: cover;
      background-position: center center;
    }

  }

  &__display {
    &__label {
      text-align: center;
      font-weight: bold;
      position: absolute;
      color: $white;
      text-shadow: 0 0 3px rgba(0, 0, 0, .5);
      left: 0;
      right: 0;
      bottom: 1.2%;

      @include media-breakpoint-down(md) {
        bottom: -3%;
      }

      @include media-breakpoint-down(sm) {
        bottom: 4%;
      }
    }
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  }

  &__image {
    padding: 5px;
  }
}

.magazine {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
  }

  &__headline {
    line-height: 0.75;
    border-bottom: .12em solid $dwbn-red;
    display: inline-block;
  }

  &__article-headline {
    padding-bottom: .3em;
    border-bottom: 2px solid $black;
    font-size: 19px!important;

    @include media-breakpoint-down(md) {
      font-size: 13px !important;
      margin-top: .5rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 16px!important;
    }
  }

  &__active {
    text-decoration: underline;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    grid-auto-flow: dense;
    max-height: calc(100vh - 301px);
    overflow-y: auto;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(2, 1fr);
      max-height: initial;
    }

  }

  &__country {
    break-inside: avoid;

    &--important {
      grid-column-end: span 2;
      grid-row-end: span 2;
    }

    &--important-single {
      grid-row-end: 1;
    }
  }

  &__article {
    cursor: pointer;

    @include media-breakpoint-down(md) {
      font-size: 11px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 15px;
    }
  }

  &__image-tile {
    position: relative;
    cursor: pointer;
  }

  &__image-placeholder {
    padding-bottom: 100%;
    background-color: $gray-400;
  }

  &__image-label {
    position: absolute;
    bottom: .5rem;
    left: .5rem;
    right: .5rem;
    color: $white;
    font-weight: bold;
    text-shadow: 0 0 2px rgba(0,0,0, .4);
    text-decoration: inherit;
  }

}


.timeline {
  padding-left: 5%;

  @include media-breakpoint-down(sm)  {
    padding-left: 0;
    margin-top: 2.5rem;
  }
}

.globe {

  &__controls {
    font-size: 3rem;
    text-align: center;
  }

  &__timeline {
    border: 2px solid $gray-900;
    height: .3rem;
    border-radius: .25rem;
    position: relative;
    overflow: hidden;
  }

  &__timeline-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: $gray-500;
    transition: width .2s ease;
  }

  &__display {
    position: relative;

    &__info {
      position: absolute;
      background: $gray-900;
      border-radius: 10px;
      color: $gray-300;
      font-size: .7em;
      padding: 4px 9px;
      top: -34px;
      text-align: center;

      &:after {
        border: 6px solid transparent;
        border-top-color: $gray-900;
        content: '';
        position: absolute;
        top: 100%;
        left: calc(50% - 6px);;
      }

      &--start {
        left: -21px;
      }

      &--end {
        right: -20px;
      }

      &--current {
        background: $dwbn-red;
        top: -58px;
        transition: left .2s ease;
        z-index: 5;

        &:after {
          border-top-color: $dwbn-red;
        }
      }
    }

    &__years {
      position: relative;
      top: -.5rem;
      width: 100%;
      font-size: .7em;
      height: 1em;
    }

    &__year {
      --label-opacity: 0;

      color: $gray-500;
      position: absolute;
      text-align: center;
      cursor: pointer;
      height: .7rem;
      border-left: 1px solid $black;

      &:hover {
        --label-opacity: 1;
        color: $black;
      }

      &--decade {
        --label-opacity: 1;
        height: 1.2rem;
        top: -.25rem
      }
    }

    &__year-label {
      position: absolute;
      bottom: -15px;
      left: -13px;
      white-space: nowrap;
      opacity: var(--label-opacity);
    }

    &__info-year {
      font-size: 1.5em;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }
}

.speed-select {
  position: relative;

  &__select {
    display: none;
    position: absolute;
    background: $black;
    color: $white;
    padding: .25rem;
    border-radius: $border-radius;
    top: -3px;
    right: calc(100%);
  }

  &__item {
    display: block;
    padding: .25rem;
    cursor: pointer;

    &--active {
      font-weight: bold;
      text-decoration: underline;
    }


    &:hover {
      text-decoration: underline;
    }

  }

  &__rotate {
    transform: rotate(var(--rotation, 0deg));

    &--0_5 {
      --rotation: -45deg;
    }

    &--1_5 {
      --rotation: 45deg;
    }

    &--2_0 {
      --rotation: 90deg;
    }
  }

  &:hover {
    .speed-select__select {
      display: flex;
    }
  }

}



.wt-line {
  display: block;
  position: relative;
  border-bottom: 1px solid $gray-400;

  &:hover {
    background-color: $gray-100;
  }

  &__month {
    font-weight: bold;
    border-bottom: 2px solid $gray-400;
    padding: .3em;
    text-transform: uppercase;
  }

  &__day {
    width: 2.5rem;
    height: 1.7rem;
    text-align: center;
    padding: .3em;
    display: inline-block;
    border-right: 1px solid $gray-400;
  }

  &__add {
    display: none;
    margin: 0;
  }

  &__title {
    padding: 0 .3em;
    border-radius: $border-radius;
    top: 0;
    background-color: $gray-300;
    width: calc(50% - 2.5rem);
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid $gray-400;
    cursor: pointer;

    &:hover {
      background-color: darken($gray-300, 10%);
    }

    &--ok {
      background-color: lighten($green, 10%);
      border-color: $green;

      &:hover {
        background-color: darken($green, 10%);
      }
    }

  }

  &__chronicle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    padding: 0 .3em;
    z-index: 10;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__buttons {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__button {
    background-color: rgba(255, 255, 255, .7);
    margin: 0;
  }

  &:hover {
    .wt-line__add {
      display: inline-block;
    }
  }

}
