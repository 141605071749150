.bs-tooltip-bottom {
  padding-top: .5rem;

  .tooltip-arrow {
    top: calc(.5rem - var(--bs-tooltip-arrow-height));
  }

}

.tooltip-inner {
  p:last-child {
    margin-bottom: 0;
  }
}
