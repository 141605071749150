[class^="dw-icon-"], [class*=" dw-icon-"] {
  @include make-icon();
  // adjust to the center of the text
  position: relative;
  top: .04em;
}

.dw-icon-alert {
  &:before {
    content: $dw-icon-alert;
  }
}
.dw-icon-bell {
  &:before {
    content: $dw-icon-bell;
  }
}
.dw-icon-calendar-month {
  &:before {
    content: $dw-icon-calendar-month;
  }
}
.dw-icon-calendar {
  &:before {
    content: $dw-icon-calendar;
  }
}
.dw-icon-ec {
  &:before {
    content: $dw-icon-ec;
  }
}
.dw-icon-center {
  &:before {
    content: $dw-icon-center;
  }
}
.dw-icon-coffee {
  &:before {
    content: $dw-icon-coffee;
  }
}
.dw-icon-diamond {
  &:before {
    content: $dw-icon-diamond;
  }
}
.dw-icon-dorje {
  &:before {
    content: $dw-icon-dorje;
  }
}
.dw-icon-facebook {
  &:before {
    content: $dw-icon-facebook;
  }
}
.dw-icon-twitter {
  &:before {
    content: $dw-icon-twitter;
  }
}
.dw-icon-play {
  &:before {
    content: $dw-icon-play;
  }
}
.dw-icon-location {
  &:before {
    content: $dw-icon-location;
  }
}
.dw-icon-friends {
  &:before {
    content: $dw-icon-friends;
  }
}
.dw-icon-inspiration {
  &:before {
    content: $dw-icon-inspiration;
  }
}
.dw-icon-letter {
  &:before {
    content: $dw-icon-letter;
  }
}
.dw-icon-mac {
  &:before {
    content: $dw-icon-mac;
  }
}
.dw-icon-mala {
  &:before {
    content: $dw-icon-mala;
  }
}
.dw-icon-mandala {
  &:before {
    content: $dw-icon-mandala;
  }
}
.dw-icon-meditation {
  &:before {
    content: $dw-icon-meditation;
  }
}
.dw-icon-hand {
  &:before {
    content: $dw-icon-hand;
  }
}
.dw-icon-news {
  &:before {
    content: $dw-icon-news;
  }
}
.dw-icon-notification {
  &:before {
    content: $dw-icon-notification;
  }
}
.dw-icon-phone {
  &:before {
    content: $dw-icon-phone;
  }
}
.dw-icon-phone1 {
  &:before {
    content: $dw-icon-phone1;
  }
}
.dw-icon-image {
  &:before {
    content: $dw-icon-image;
  }
}
.dw-icon-profile-photo {
  &:before {
    content: $dw-icon-profile-photo;
  }
}
.dw-icon-project {
  &:before {
    content: $dw-icon-project;
  }
}
.dw-icon-tools {
  &:before {
    content: $dw-icon-tools;
  }
}
.dw-icon-wheelbarrow {
  &:before {
    content: $dw-icon-wheelbarrow;
  }
}
.dw-icon-woman {
  &:before {
    content: $dw-icon-woman;
  }
}
.dw-icon-man {
  &:before {
    content: $dw-icon-man;
  }
}
.dw-icon-welcome {
  &:before {
    content: $dw-icon-welcome;
  }
}
.dw-icon-recording {
  &:before {
    content: $dw-icon-recording;
  }
}

