/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "scss/variable-overrides";
@import 'bootstrap/scss/bootstrap';
@import 'scss/mixins';

@import "scss/fonts";

@import "scss/base";

// markup from the old editor
@import "scss/legacy";

// new bootstrap markup
@import "scss/components";
@import "scss/admin/includes";

@media (min-width: 768px) {
  .max-w-lg {
    max-width: 32rem !important
  }
}
