.select-item {
  --bs-border-opacity: 0;
  transition: border 0.2s ease, color 0.2s ease;

  &__active {
    --bs-border-opacity: 1;
    color: $primary;
  }

  &:hover {
    --bs-border-opacity: 1;
    color: $info;
  }
}


.cat__input {
  background: transparent;
  border: none;
  padding: 0;
  color: var(--bs-badge-color);
  border-bottom: 1px solid var(--bs-badge-color);
  height: 1em;
  text-align: center;
  outline: none;
  box-shadow: none;
  min-width: 2em;
}


.input-group .btn {
  --bs-btn-border-width: var(--bs-border-width);
}
