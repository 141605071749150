.footer {

  .nav-link {
    --bs-nav-link-color: #{$white};
    --bs-nav-link-padding-y: 0;
    --bs-nav-link-hover-color: #{$white};

    &:hover, &:focus {
      text-decoration: underline;
    }
  }


  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
