input.search {

  &__input {
    --transition-speed: .3s;

    width: 0;
    transition: width var(--transition-speed) ease-out, padding var(--transition-speed) ease-out, margin var(--transition-speed) ease-out;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    height: auto;

    &--expanded {
      width: 180px;
      padding-right: .5rem;
      margin-left: .5rem;
    }

  }
}

.search {
  &__tile {
    .img-responsive {
      object-fit: cover;
    }
  }
}
