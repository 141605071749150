.icon-col {
  line-height: 1;
  font-size: 3rem;
  width: 77px;

  i {
    display: block;
    margin-top: -.17em;
  }

  .dw-icon-coffee {
    display: block;
    font-size: 1.2em;
    margin-top: -.56em;
    margin-left: -0.14em;
  }

  .dw-icon-alert {
    display: block;
  }
}

.icon-box-content--with-icon {
  width: calc(100% - 77px);
}
