.line {
  max-width: 100%;
  width: 146px;
  position: relative;
  display: inline-block;

  &__input {
    display: block;
    border: none;
    outline: none;
    border-bottom: 1px solid $gray-300;
    height: 21px;
    width: 100%;
    font-size: 14px;
    padding: 2px 7px;
    background: transparent;
  }

  &__suggestions {
    background-color: #fff;
    z-index: 5;
    position: absolute;
    list-style: none;
    border: 1px solid $gray-300;
    border-bottom: none;
    margin: 0;
    padding: 0;
    left: 0;
    top: 25px;
    font-size: 12px;

    &.show {
      min-width: 100%;
      box-shadow: 0 2px 3px rgba(0,0,0,.2);
    }

    > li {
      padding: 4px 8px;

      &:last-child {
        border-bottom: 1px solid $gray-300;
      }

    }
  }

  &__desc {
    color: $gray-500;
  }

  &__li {
    cursor: pointer;

    &.active, &:hover {
      color: $primary;
      background-color: $gray-100;
    }
  }
}
