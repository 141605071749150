.cell--hover:hover {
  background-color: #efefef;
}

.border--dashed {
  --bs-border-style: dashed;
}

.cursor-pointer {
  cursor: pointer;
}

.linked {
  cursor: pointer;
  overflow: hidden;

  &:hover {
    box-shadow: 0 0 5px rgb(108 108 108 / 30%)
  }
}

// backgrounds
.bg-medium {
  --bs-badge-color: #{$dark};
}

// width

.w-0 { width: 0; }
.w-2 { width: 2rem; }
.w-7 { width: 7rem; }

// heights

.h-2 {
  height: 2rem;
}

.h-8 {
  height: 8rem;
}

.h-50vh {
  height: 50vh;
}

.h-75vh {
  height: 75vh;
}

.h-modal {
  height: var(--bs-modal-height);
}


// min-height
.min-h-100 {
  min-height: 100%;
}

.min-h-650 {
  min-height: 650px;
}

// min-width

.min-w-1_2em {
  min-width: 1.2em;
}

// max-width
.max-w-100 {
  max-width: 100%;
}

.max-w-350 {
  max-width: 350px;
}

.max-w-450 {
  max-width: 450px;
}

.max-w-670 {
  max-width: 670px;
}

.max-w-800 {
  max-width: 800px;
}

// background

.bg-white {
  background-color: $white;
}

.bg-yellow-light {
  background-color: $yellow-light;
}

.bg-black {
  background-color: $black;
}

// z-index
.z-100 {
  z-index: 100;
}

// whitepspace

.ws-none { white-space: nowrap; }
