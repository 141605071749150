h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
  font-weight: 500;
}

// container for vertical side bar menu
.section.section--side .menu-container {
  border: 1px solid #d7d8da;
  border-radius: 8px;
  padding: 20px;
}
