.text-center {
  .blockquote-footer {
    &:after {
      content: "\00A0\2014"; // nbsp, em dash
    }
  }
}


.blockquote {
  &__text {
    &:before {
      content: '„';
      position: absolute;
      display: inline-block;
      left: -.4em;
      top: -.1em;
    }

    &:after {
      content: '”';
      display: inline-block;
      margin-left: -0.04em;
    }
  }
}
