.page-loader {
  --bs-spinner-width: 3rem;
  --bs-spinner-height: 3rem;
  --bs-spinner-border-width: 0.18em;

  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  z-index: 6;
}

.spinner-border-text {
  --bs-spinner-width: .9em;
  --bs-spinner-height: .9em;
  --bs-spinner-border-width: 0.1em;
}
