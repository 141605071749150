.inspiration {
  &__image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    z-index: 7;
  }

  &__info-col {
    height: 100%;
    overflow-y: auto;
  }

  &__button {
    --bs-btn-border-width: 0;
    background-color: rgba($dark, .6);
  }
}
