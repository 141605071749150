.focal {

  &__grid {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__row {
    flex-grow: 1;
    display: flex;
    border-bottom: 1px solid $white;
  }

  &__col {
    flex-grow: 1;
    border-right: 1px solid $white;
    background: rgba(255, 255, 255, 0);
    transition: background-color .5s ease;
    cursor: pointer;
    position: relative;

    &:hover, &--selected {
      background: rgba(255, 255, 255, .4);
    }

    &--selected:after {
      content: "X";
      position: absolute;
      left: 0;
      top: calc(50% - 10px);
      width: 100%;
      text-align: center;
      color: white;
      font-size: 20px;
      line-height: 20px;
      text-shadow: 0 0 3px black;
      font-weight: bold;
    }
  }
}
