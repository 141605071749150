.frame-container {
    position: relative;
  }
  
  .fill-frame {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }