.map-wrapper {
  position: relative;
  padding-bottom: 50%;
  border-radius: $border-radius * 2;
  overflow: hidden;
  background: $gray-400;

  &--thin {
    padding-bottom: 33.333%;
  }

  &--square {
    padding-bottom: 100%;
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 85%;
  }

}

.map {
  position: absolute;
  left: 0; top: 0;
  height: 100%; width: 100%;
}
