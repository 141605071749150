.profile-btn {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: opacity .3s ease;
  position: relative;
  outline: none;

  &:hover {
    opacity: .7;
  }

  &:after {
    display: none;
  }

  &__image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }

  .badge {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.profile {
  &__errorMessage {
    max-width: 550px;
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }

  &__memberInfo {
    display: flex;
    align-items: baseline;
    gap: 10px;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      justify-content: start;
    }

  }
}

