.menu {
  &__logo {
    width: 33px;
  }
}

#mainMenu {
  --bs-nav-link-font-size: #{$h5-font-size * .8};

  flex-grow: initial;

  .nav-link {
    letter-spacing: .05em;

    &.active {
      // --bs-nav-link-font-weight: 600;
      text-shadow: 0 0 1px #{$dark};
    }

  }

}
