.loader {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  z-index: 400;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
