.inter-project {
  &__image-wrapper {
    position: relative;
    padding-bottom: 60.7%;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 100px;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+14,0.65+100 */
      background: -moz-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 14%,
        rgba(0, 0, 0, 0.65) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 14%,
        rgba(0, 0, 0, 0.65) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 14%,
        rgba(0, 0, 0, 0.65) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__header {
    position: absolute;
    width: calc(100% - 50px);
    bottom: 20px;
    left: 20px;
    z-index: 1;

    h5 {
      color: white;
    }
  }

  &__link-to-project {
    text-align: right;
    margin-top: 10px;
  }
}
