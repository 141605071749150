@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/brands";

/* ubuntu-300 - latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-300.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-300italic - latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-regular - latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-italic - latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-500 - latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-500.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-500italic - latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-500italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-500italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-700 - latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-700.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-700italic - latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/ubuntu/ubuntu-v20-latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}

// for mock ui
@font-face {
  font-family: 'Virgil';
  src: url('/assets/fonts/Virgil.woff2') format('woff2');
}

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?p4j84w') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?p4j84w') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?p4j84w##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
