// colors

$dwbn-red: #C90033;
$red: $dwbn-red;
$blue-light: #f7fafd;
$blue:  #149bc9;
$blue-dark: #4673d6;
$yellow: #ffd772;
$yellow-light: #fff3dd;
$green: #14d57e;
$cyan:    #0dcaf0;
$gray-100: #f8f9fa;
$gray-300: #dbdbdb;
$gray-400: #9e9e9e;
$gray-500: #6b7280;
$gray-900: #212529;
$black: #000;
$white: #fff;

$primary: $dwbn-red;
$secondary: $blue;
$warning: $yellow;
$success: $green;

$info:          $blue-dark !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$gray:          $gray-500 !default;
$dark:          $gray-900 !default;

$primary-border-subtle: $red;
$danger-border-subtle: $red;
$danger-text-emphasis: $red;
$primary-text-emphasis: $red;
$danger-bg-subtle: $white;
$primary-bg-subtle: $white;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "yellow":     $yellow,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "gray300":    $gray-300,
  "medium":     $gray-400,
  "gray":       $gray,
  "dark":       $dark
) !default;


// grid

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px
);

$container-max-widths: (
  sm: 576px,
  md: 992px,
  lg: 1200px,
  xl: 1500px,
  xxl: 1500px
);

// font-sizes & typography

$font-family-base:  'Ubuntu', sans-serif;
$font-weight-base: 400;

$fa-font-path: '/assets/fonts/fontawesome';

$icomoon-font-family: "icomoon";
$icomoon-font-path: "/assets/fonts/icomoon";


$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * .8;

$h1-font-size:                $font-size-base * 2.2 !default;
$h2-font-size:                $font-size-base * 1.5 !default;
$h3-font-size:                $font-size-base * 1.4 !default;
$h4-font-size:                $font-size-base * 1.3 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;

$font-sizes: (
  huge: $h1-font-size * 3,
  large: $h1-font-size * 2.2,
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $font-size-sm
);

$headings-font-weight: 700;
$headings-margin-bottom: 1rem;

$link-decoration: none;

$border-radius: .5rem;

// Icons

$dw-icon-alert: "\e900";
$dw-icon-bell: "\e901";
$dw-icon-calendar-month: "\e902";
$dw-icon-calendar: "\e903";
$dw-icon-ec: "\e904";
$dw-icon-center: "\e905";
$dw-icon-coffee: "\e906";
$dw-icon-diamond: "\e907";
$dw-icon-dorje: "\e908";
$dw-icon-facebook: "\e909";
$dw-icon-twitter: "\e90a";
$dw-icon-play: "\e90b";
$dw-icon-location: "\e90c";
$dw-icon-friends: "\e90d";
$dw-icon-inspiration: "\e90e";
$dw-icon-letter: "\e90f";
$dw-icon-mac: "\e910";
$dw-icon-mala: "\e911";
$dw-icon-mandala: "\e912";
$dw-icon-meditation: "\e913";
$dw-icon-hand: "\e914";
$dw-icon-news: "\e915";
$dw-icon-notification: "\e916";
$dw-icon-phone: "\e917";
$dw-icon-phone1: "\e918";
$dw-icon-image: "\e919";
$dw-icon-profile-photo: "\e91a";
$dw-icon-project: "\e91b";
$dw-icon-tools: "\e91c";
$dw-icon-wheelbarrow: "\e91d";
$dw-icon-woman: "\e91e";
$dw-icon-man: "\e91f";
$dw-icon-welcome: "\e920";
$dw-icon-recording: "\e921";

// navbar

$navbar-nav-link-padding-x: 1rem;
$navbar-light-color: $dark;

// inputs

$input-border-width: 1px;
$input-border-color: $black;

// buttons
$btn-border-width: 2px;
$btn-padding-y: .25rem;

$btn-font-size-sm: $font-size-sm;

// badges
$badge-font-weight: 500;
$badge-padding-x: 1rem;
$badge-padding-y: 0.5rem;

// spinner
$spinner-animation-speed: 1s;

// Modal
$modal-content-bg: $white;
$modal-content-border-radius: 20px;
$modal-header-border-color: $primary;
$btn-close-color: $primary;
$modal-header-border-width: 2px;
$modal-footer-margin-between: 0;

$modal-backdrop-opacity: 0.75;

// alerts
$alert-border-radius: 0;
$alert-border-width: 2px;
$alert-padding-x: 0;
$alert-padding-y: .5rem;

// tables
$table-bg: transparent;
$table-cell-vertical-align: middle;

// accordeon
$accordion-bg: $gray-100;
$accordion-border-width: 0;
$accordion-button-active-bg: $accordion-bg;
$accordion-button-focus-box-shadow: none;

// pagination
$pagination-font-size: 0.75rem;
