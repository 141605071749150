:target {
  scroll-margin-top: 90px;
}

body, html {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

app-root {
  min-height: 100%;
  position: relative;
  display: block;
}

.page {
  padding-top: 80px;
  padding-bottom: 120px;
}


.template--post, .template--bare {
  --bs-body-bg: #{$blue-light};
  --bs-body-bg-rgb: #{to-rgb($blue-light)};
}
