.modal-dialog {
  &--dwc {
    .modal-content {
      --bs-modal-header-padding: 1rem 0 .6rem;
      --bs-modal-padding: 1rem 0;

      padding: 0 .75rem;
    }

    .modal-header {
      .btn-close {
        --bs-btn-close-color: #{$primary};
        outline: none;
        box-shadow: none;
      }
    }

    .modal-body {
      overflow-x: hidden;
    }

    .modal-title {
      font-weight: normal;
    }
  }

  &--inspiration {
    --bs-modal-width: 94vw;
    --bs-modal-height: 85vh;

    .modal-content {
      --bs-modal-padding: 0;
      border-radius: $modal-content-border-radius * .5;
      overflow: hidden;
    }

    .modal-body {
      padding: 0!important;

      @include media-breakpoint-up(md) {
        height: var(--bs-modal-height);
        overflow: hidden;
      }

      .container-fluid, .row {
        @include media-breakpoint-up(md) {
          height: var(--bs-modal-height);
        }
      }

    }
  }


  &--sidebar {
    --bs-modal-height: 100vh;
    --bs-modal-margin: 0;
    --bs-modal-border-width: 0;
    --bs-modal-border-radius: 0;

    width: 94vw;
    height: var(--bs-modal-height);
    margin: 0 0 0 auto;

    .modal-content {
      height: var(--bs-modal-height);
    }

    .modal-body {
      overflow-x: hidden;
      padding: 0 var(--bs-modal-header-padding-x)!important;
      margin: 0 .25rem;

      /* small scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        width: 8px;
      }

      scrollbar-width: thin;  /* Firefox */
    }
  }
}

.modal {
  &--sidebar {
    &:not(.show) {
      // opacity: 1!important;
    }

    &.show {
      .modal-dialog {
        transform: none !important;
      }
    }

    .modal-dialog {
      transform: translate(100%, 0)!important;
    }
  }

  &__loader {
    z-index: 999;
  }

  &__fill {
    width: 100%;
    height: calc(100vh - 200px);
  }
}
