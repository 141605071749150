.event-details {
  --left-margin: 3rem;
  --dwbn-events-item-padding-x: 0;
  --v-spacer: 1.5rem;

  .dwbn-events {
    &__item {
      display: block;
    }

    &__topic_teachers_language_entrancefees {
    }

    &__location {
      display: block;
      margin: var(--v-spacer) 0;
      padding-left: var(--left-margin);
      position: relative;

      &:before {
        @include make-icon($dw-icon-location);

        color: $primary;
        position: absolute;
        left: -.6rem;
        top: -0.2rem;
        font-size: 3rem;
      }
    }

    &__dates {
      padding-left: var(--left-margin);
      position: relative;
      display: block;

      .until {
        display: none;
      }

      .time-from {
        display: inline-block;
        padding-left: 1rem;
      }

      &:before {
        @include make-icon($dw-icon-calendar);

        color: $primary;
        position: absolute;
        left: -.2rem;
        top: 1rem;
        font-size: 2.5rem;
      }

      .dwbn-events {
        &__item {
          padding-left: 0;
          display: flex;
        }

        &__daterange {
          width: max(18%, 190px);
        }

        &__topic_teachers_language_entrancefees {
          width: calc(100% - max(18%, 190px));
        }

        &__topic {
          font-weight: 500;
        }
      }

    }

    &__entrancefees {
      display: block;
      margin-top: .25rem;
    }
  }

  > .dwbn-events__item--multiday {
    padding: 0;
  }

  > .dwbn-events__item {

    display: flex;
    flex-direction: column;

    > .topic_teachers_language_entrancefees {
      order: -1
    }

    > .topic_teachers_language_entrancefees, .topic_teachers_language {
      font-size: $h1-font-size;
      font-weight: $headings-font-weight;
    }

    > .daterange {
      display: block;

      position: relative;
      width: 100%;
      max-width: initial;
      font-size: $h4-font-size;
      font-weight: 500;
      line-height: 1;
    }

    > .description_website_entrancefees_registration, > .description_website_registration {
      padding-left: var(--left-margin);
      position: relative;
      display: block;

      &:before {
        @include make-icon($dw-icon-phone);

        color: $primary;
        position: absolute;
        left: -.6rem;
        top: -0.2rem;
        font-size: 3rem;
      }
    }
  }
}
