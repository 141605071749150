.members-list {
  &__member {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
  }

  &__contact-icon {
    position: absolute;
    color: $primary;
    right: 2px;
    bottom: -1px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    font-size: 12px;
  }

  &__member-image {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #eee;
    text-align: center;
    object-fit: cover;
  }

  &__content {
    width:calc(100% - 66px);
  }
}
