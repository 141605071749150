.image-placeholder {
  padding-bottom: 60%;
  border-radius: 8px;

  background-size: 20%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('/assets/icons/dwbn/photo.svg');
  background-color: $gray-300;
}

.img-wrapper {
  display: block;
  max-width: 100%;
  margin-bottom: 1rem;
  margin-top: .14rem;

  &[data-rounded-corners="true"]{
    overflow: hidden;
    border-radius: $border-radius;
  }
}

.align {
  &-left {
    float: left;
    margin-right: 1rem;
  }

  &-right {
    float: right;
    margin-left: 1rem;
  }

  &-top {
    margin-top: 0;
  }

  &-alone {
    margin: 0;
  }

  &-center {
    margin-left: auto;
    margin-right: auto;
  }
}


.img-placeholder {
  background: #f0f0f0;
  position: relative;
  display: block;
  overflow: hidden;

  &:before {
    content: 'media loading...';
    width: 216px;
    color: #bebebe;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -108px;
    margin-top: -.5em;
  }

  > .img-responsive {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 100%;
  }
}

.img-responsive {
  max-width: 100%;
}

