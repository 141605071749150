.tile {
  position: relative;
  border-radius: $border-radius;
  overflow: hidden;

  &__placeholder {
    display: block;
    position: relative;
    background-color: $gray-300;

    &--p60 {
      padding-bottom: 60%;
    }
  }

  &__gradient {
    position: absolute;
    height: 60%;
    bottom: 0;
    left: 0;
    right: 0;
    // Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+18,0.65+99
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 18%, rgba(0,0,0,0.65) 99%, rgba(0,0,0,0.65) 100%); // FF3.6-15
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 18%,rgba(0,0,0,0.65) 99%,rgba(0,0,0,0.65) 100%); // Chrome10-25,Safari5.1-6
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 18%,rgba(0,0,0,0.65) 99%,rgba(0,0,0,0.65) 100%); // W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); // IE6-9
    margin: 0;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }

}
