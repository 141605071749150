.badge {
  &--circle {
    width: 1.5em;
    height: 1.5em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}
