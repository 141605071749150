.section {
  &__heading {
    left: -2px;
    transform: translateY(calc(-100% - .5rem - 2px));
    text-transform: capitalize;
  }
}

.editor-inset {

  &__overlay {
    position: absolute;
    background-color: rgba(255, 255, 255, .6);
    top: 0; left:0; right: 0; bottom: 0;
    cursor: pointer;
    transition: background-color .5s;
    // z-index: 200;

    &:hover {
      background-color: rgba(255, 255, 255, .2);
    }

  }

}

.edit-buttons {
  position: absolute;
  background: rgba(255, 255, 255, .5);
  transition: background-color 300ms;
  padding: $grid-gutter-width * .5;
  z-index: 50;
  width: calc(100% - #{$grid-gutter-width});
  height: 100%;

  &:hover {
    background: rgba(255, 255, 255, .1);
  }

  &--accordion {
    top: 0;
    right: 0;
    left: 0;
    text-align: right;
  }

  &--grid {
    top: 0;
  }

  &--slider {
    bottom: 0;
    left: 0;
    right: 0;
  }

}
