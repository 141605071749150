.pi-chart {
  border-radius: 50%;
  padding-bottom: 100%;
  background-color: $gray-300;
  position: relative;

  &__inner {
    width: 78%;
    position: absolute;
    left: 11%;
    top: 11%;
    border-radius: 50%;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
      background-color: $white;
      border-radius: 50%;
    }
  }

  &__fill {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $red;
    border-radius: 50%;
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
