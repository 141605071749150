@use "node_modules/@dwbn-it/event-webcomponents/src/scss/variables" with (
  $spacer: 1rem,
  $quote-before-content: '',
  $quote-after-content: ''
);
@use "node_modules/@dwbn-it/event-webcomponents/src/scss/event-webcomponents";

.dwbn-events {
  &__list {
    --dwbn-events-border-width: 0;

    &.center-events {
      .topic_location_teachers_language {
        max-width: max(calc(100% - 15em), 66%);
      }

    }

    .cancelled {
      .teachers, .location {
        text-decoration: line-through;
      }
    }
  }

  &__topic {
    > .state {
      &:after {
        content: ': ';
      }
    }
  }

  &__item {

    border-left: 3px solid $white;

    &:nth-child(2n) {
      background-color: $gray-100;
      border-color: $gray-100;
    }

    &--multiday {
      .time-from, .time-until {
        display: none;
      }
    }

    &--recurring {

      .dwbn-events {
        &__time-until {
          display: none;
        }

        &__topic_location_teachers_language {
          max-width: max(calc(100% - 23rem), 42%);
        }
      }

    }

    &--today {
      border-color: $primary!important;
      font-weight: 500;
    }

  }

  &__topic_location_teachers_language {
    max-width: 66%;
  }

  &__language {
    margin-left: .3em;
  }
}

.eventlist {
  &__message {
    display: block;
    padding-bottom: 1rem;
  }
}

.radius-events {
  .dwbn-events {
    &__location {
      width: 30%;
      max-width: 10em;
      padding-right: var(--dwbn-events-item-padding-x);

      &:before {
        display: none;
      }
    }

    &__topic_teachers_language {
      max-width: min(calc(100% - 25em), 57%);
    }
  }
}
