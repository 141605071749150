.link-text {
  text-decoration: inherit;
  color: inherit;

  &.bg-primary {

    color: $white;

    &:hover, &:active {
      --bs-link-hover-color: #{$white};
      background-color: darken($primary, 10%)!important;
    }
  }

}
