:root {
  --ck-z-default: #{$zindex-modal};
}

#tinymce {
  padding: 1rem;
}

.html-editor {
  display: block;
  min-height: 400px;
}

.tox .tox-edit-area::before {
  border-color: $focus-ring-color!important;
}
